<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="dark">
    <div class="card">
      <div class="card-header">
        <div>
          <h4 class="font-weight-bold mb-0">Top 5 Policies</h4>
          <p class="card-text font-small-2" style="height:25px">
            Top {{ filters.top }} policies that are used by the Third Parties are listed
            below.
          </p>
        </div>

        <div style="width: auto">
          <!-- <b-form-select
            v-model="filters.top"
            :options="limitOption"
            size="sm"
          /> -->
        </div>
      </div>

      <div class="card-body position-relative" style="height: 275px">
        <radial-chart
          :series="series"
          :legend="legend"
          @chart-click="handleChartClick"
          v-if="totalUsagePercent > 0"
        />
        <div
          v-else
          class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <feather-icon icon="MehIcon" size="36" />
          <p class="mt-2 text-center w-75 text-muted">
            It appears that the third parties involved have not implemented any specific
            policies.
          </p>
        </div>
      </div>

      <!-- <div class="card-body">
        <chartjs-component-polar-area-chart :height="233" :data="chartData" />
      </div> -->

      <!-- <div class="table-responsive" style="max-height: 300px; overflow-y: auto">
        <table class="table table-hover table-sm">
          <thead role="rowgroup" class="sticky-header">
            <tr role="row">
              <th role="columnheader" scope="col"></th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col" class="text-center">Usage</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-if="topPolicies && topPolicies != null && topPolicies.length > 0">
              <tr v-for="(policy, index) in topPolicies" :key="policy.policy_id">
                <td>{{ index + 1 }}</td>
                <td style="max-width: 20vw">{{ policy.policy_name }}</td>
                <td>
                  <div class="w-100 d-flex align-items-center justify-content-center">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bold text-body-heading mr-1"
                        >{{ policy.usage_percentage }}%</span
                      >
                      <small-progress-chart :value="policy.usage_percentage" />
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <template v-else>
              <empty-table-section title="Policies Empty">
                <template #content>
                  <p class="text-center">
                    It seems like there are no policies at the moment.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>
      </div> -->
    </div>
  </b-overlay>
</template>

<script>
import SmallProgressChart from "../../components/SmallProgressChart.vue";
import { BMedia, BMediaAside, BMediaBody, BOverlay, BFormSelect } from "bootstrap-vue";
import NewDashboardMixins from "../../../../mixins/NewDashboardMixins";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import EmptyTableSection from "../../../../components/EmptyTableSection.vue";
import ChartjsComponentPolarAreaChart from "./components/ChartjsComponentPolarAreaChart.vue";
import RadialChart from "../../components/RadialChart.vue";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};
const bgColors = [
  chartColors.primaryColorShade,
  chartColors.warningColorShade,
  chartColors.infoColorShade,
  chartColors.greyColor,
  chartColors.successColorShade,
];
export default {
  mixins: [NewDashboardMixins, ResponseMixins],
  components: {
    SmallProgressChart,
    BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay,
    BFormSelect,
    EmptyTableSection,
    ChartjsComponentPolarAreaChart,
    RadialChart,
    FeatherIcon,
  },
  data() {
    return {
      totalUsagePercent: 0,
      topPolicies: null,
      showOverlay: false,
      filters: {
        top: 5,
      },
      limitOption: [
        { text: "Top 5", value: 5 },
        { text: "Top 10", value: 10 },
        { text: "Top 20", value: 20 },
      ],

      chartData: null,

      legend: {
        orient: "vertical",
        left: 0,
        top: "middle",
        formatter: function (name) {
          // Customize the legend text truncation
          const maxLength = 25; // Maximum length of legend text
          if (name.length > maxLength) {
            return name.substring(0, maxLength) + "..."; // Truncate text and add ellipsis
          }
          return name;
        },
      },
      series: [
        {
          name: "Risks",
          type: "pie",
          radius: ["0%", "75%"], // Set the inner and outer radius for the doughnut chart
          center: ["60%", "50%"],
          label: {
            show: false, // Hide the labels on the pie chart
          },
          data: [
            // { value: 0, name: "Low", itemStyle: { color: "#4CAF50" } }, // Green color for "Low"
            // { value: 0, name: "Medium", itemStyle: { color: "#FFC107" } }, // Yellow color for "Medium"
            // { value: 0, name: "High", itemStyle: { color: "#FF5722" } }, // Orange color for "High"
            // { value: 0, name: "Critical", itemStyle: { color: "#F44336" } }, // Red color for "Critical"
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  },
  props: {
    vendorId: {
      type: String,
      // required: true,
    },
  },

  watch: {
    filters: {
      deep: true,
      handler: function (newValue) {
        this.getDashboardTopPoliciesAndSetData(newValue);
      },
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getDashboardTopPoliciesAndSetData(this.filters);
    },

    getDashboardTopPoliciesAndSetData(params) {
      this.showOverlay = true;
      this.getDashboardTopPolicies({...params,vendorId:this.vendorId})
        .then((res) => {
          console.log(res);

          const newSeriesData = res.data.top_policies.map((polc, i) => {
            this.totalUsagePercent += Number(polc.usage_percentage);
            return {
              value: polc.usage_percentage,
              name: polc.policy_name,
              itemStyle: { color: bgColors[i] },
            };
          });
          this.$set(this.series[0], "data", newSeriesData);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    // getDashboardTopPoliciesAndSetData(params) {
    //   this.showOverlay = true;
    //   this.getDashboardTopPolicies(params)
    //     .then((res) => {
    //       console.log(res);
    //       this.topPolicies = res.data.top_policies;

    //       this.chartData = {
    //         labels: res.data.top_policies.map((polc) => polc.policy_name),
    //         datasets: [
    //           {
    //             label: "Policies",
    //             backgroundColor: [
    //               chartColors.primaryColorShade,
    //               chartColors.warningColorShade,
    //               chartColors.infoColorShade,
    //               chartColors.greyColor,
    //               chartColors.successColorShade,
    //             ],
    //             data: res.data.top_policies.map(
    //               (polc) => polc.usage_percentage
    //             ),
    //             borderWidth: 0,
    //           },
    //         ],
    //       };
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.handleError(err);
    //     })
    //     .finally(() => {
    //       this.showOverlay = false;
    //     });
    // },

    handleChartClick(section) {
      this.$router.push(`/policies`);
    },

    truncate(input) {
      if (input.length > 40) {
        return input.substring(0, 40) + "...";
      }
      return input;
    },
  },
};
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 999; /* Ensure it's above other content */
}
</style>
