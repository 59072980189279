<template>
    <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="dark">
            <div class="d-flex justify-content-around">
                <b-button variant="outline-success">
                  <h3>Total Third Parties</h3>
                  <b-badge variant="success">{{ total }}</b-badge>
                </b-button>
                <b-button variant="outline-info">
                  <h3>Invited Third Parties</h3>
                  <b-badge variant="info">{{ invited }}</b-badge>
                </b-button>
                <b-button variant="outline-warning">
                  <h3>Joined Third Parties</h3>
                  <b-badge variant="warning">{{ joined }}</b-badge>
                </b-button>
            </div>

    </b-overlay>
  </template>
  
  <script>
  import NewDashboardMixins from "../../../../mixins/NewDashboardMixins";
  import { BOverlay,BBadge,BButton } from "bootstrap-vue";
  import ResponseMixins from "../../../../mixins/ResponseMixins";
  export default {
    components: { BOverlay,BBadge,BButton },
    mixins: [NewDashboardMixins, ResponseMixins],
    props: {
      // data: {
      //   type: Object,
      //   default: function () {
      //     return {
      //       labels: [
      //         "Unapproved",
      //         "In Review",
      //         "Pending",
      //         "Approved",
      //         "Expired",
      //         "Rejected",
      //       ],
      //       seriesData: [1000, 200, 568, 1213, 234, 753],
      //     };
      //   },
      // },
    },
  
    computed: {
      options() {
        return {
          indexAxis: "y",
          title: {
            text: "Test",
            x: "center",
          },
          grid: {
            left: 0,
            right: 20,
            top: 100,
            bottom: 70,
            containLabel: true,
          },
          yAxis: [
            {
              type: "value",
              splitLine: { show: false },
            },
          ],
          xAxis: {
            type: "category",
            data: this.labels,
            splitLine: { show: false },
          },
          series: [
            {
              type: "bar",
              barWidth: 18,
              data: this.series,
              itemStyle: {
            color: '#6495ED'
          }
            },
          ],
        };
      },
    },
  
    data() {
      return {
        showOverlay: false,
        statusCounts: null,
        joined:0,
        invited:0,
        total:0,
        labels:[' Total','Joined','Invited'],
      };
    },
  
    mounted() {
      this.load();
    },
  
    methods: {
      load() {
        this.getDashboardThirdPartiesCountsAndSetData();
      },
  
      getDashboardThirdPartiesCountsAndSetData(params) {
        this.showOverlay = true;
        this.getDashboardThirdPartiesCounts(params)
          .then((res) => {
            console.log(res,'getDashboardThirdPartiesCounts');
            
            // this.labels = Object.keys(res.data.evidence_status_counts);
            [this.total,this.joined,this.invited] = [res.data.data.total_vendors_count,res.data.data.joined_vendors_count,res.data.data.invited_vendors_count];
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      },
  
    //   handleChartClick(val) {
    //     const section = val.toLowerCase();
    //     let value = null;
    //     if (section == "rejected") {
    //       value = 4;
    //     } else if (section == "approved") {
    //       value = 3;
    //     } else if (section == "uploaded") {
    //       value = 2;
    //     } else if (section == "requested") {
    //       value = 1;
    //     }
    //     if (value != null) {
    //       this.$router.push(`/responses?evidence_status=${value}`);
    //     }
    //   },
    },
  };
  </script>
  
  <style>

</style>
  