<template>
  <e-charts autoresize :options="option" theme="theme-color" auto-resize />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    series: {
      type: Array,
      default: null,
    },

    legend: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          confine:true,
        },
        legend: {
          orient: "vertical",
          left: 0,
          top: "middle",
        },
        series: this.series,
      },
    };
  },

  mounted() {},

  methods: {},
};
</script>
<style scoped>
.echarts {
  width: inherit;
  height: inherit;
}
</style>
