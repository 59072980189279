<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="dark">
    <div class="card">
      <div
        class="card-header d-flex flex-column align-items-start justify-content-start pb-0"
      >
        <h4 class="font-weight-bold mb-0">Third Parties Awaiting Reviews</h4>
        <!-- <p class="mb-0">Description</p> -->
      </div>

      <div
        class="card-body d-flex align-items-center justify-content-center w-100"
        style="height: 275px"
      >
        <horizontal-bar-chart style="width:100%" :optionData="options" @chart-click="handleChartClick" />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import NewDashboardMixins from "../../../../mixins/NewDashboardMixins";
import HorizontalBarChart from "../../components/HorizontalBarChart.vue";
import { BOverlay } from "bootstrap-vue";
import ResponseMixins from "../../../../mixins/ResponseMixins";
export default {
  components: { HorizontalBarChart, BOverlay },
  mixins: [NewDashboardMixins, ResponseMixins],
  props: {
    // data: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       labels: [
    //         "Unapproved",
    //         "In Review",
    //         "Pending",
    //         "Approved",
    //         "Expired",
    //         "Rejected",
    //       ],
    //       seriesData: [1000, 200, 568, 1213, 234, 753],
    //     };
    //   },
    // },
  },

  computed: {
    options() {
      return {
        indexAxis: "y",
        title: {
          text: "Test",
          x: "center",
        },
        grid: {
          left: 0,
          right: 20,
          top: 100,
          bottom: 70,
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: {
          type: "category",
          data: this.labels,
          splitLine: { show: false },
        },
        series: [
          {
            type: "bar",
            barWidth: 18,
            data: this.series,
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      };
    },
  },

  data() {
    return {
      showOverlay: false,
      statusCounts: null,
      labels: null,
      series: null,
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getDashboardThirdPartiesStatusesAndSetData();
    },

    getDashboardThirdPartiesStatusesAndSetData(params) {
      this.showOverlay = true;
      this.getDashboardThirdPartiesStatuses(params)
        .then((res) => {
          console.log(res);
          this.statusCounts = res.data.evidence_status_counts;
          this.labels = Object.keys(res.data.evidence_status_counts);
          this.series = Object.values(res.data.evidence_status_counts);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleChartClick(val) {
      const section = val.toLowerCase();
      let value = null;
      if (section == "rejected") {
        value = 4;
      } else if (section == "approved") {
        value = 3;
      } else if (section == "uploaded") {
        value = 2;
      } else if (section == "requested") {
        value = 1;
      }
      if (value != null) {
        this.$router.push(`/responses?evidence_status=${value}`);
      }
    },
  },
};
</script>

<style></style>
