<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="dark">
    <div class="card">
      <div
        class="card-header d-flex flex-column align-items-start justify-content-start pb-0"
      >
        <h4 class="font-weight-bold mb-0">Open Risks</h4>
        <!-- <p class="mb-0">Description</p> -->
      </div>

      <div
        class="card-body d-flex align-items-center justify-content-center w-100"
        style="height: 275px"
      >
        <radial-chart v-if="low||medium||high" :series="series" :legend="legend" @chart-click="handleChartClick" />
        <div
          v-else
          class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <feather-icon icon="MehIcon" size="36" />
          <p class="mt-2 text-center w-75 text-muted">
            It appears that the we have no data available in risks.
          </p>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import RadialChart from "../../components/RadialChart.vue";
import { BOverlay } from "bootstrap-vue";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: { RadialChart, BOverlay,FeatherIcon },

  props: {
    low: {
      type: Number,
      required: true,
    },
    medium: {
      type: Number,
      required: true,
    },
    high: {
      type: Number,
      required: true,
    },
    showOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      legend: {
        orient: "vertical",
        left: 0,
        top: "middle",
      },

      series: [
        {
          name: "Risks",
          type: "pie",
          radius: ["50%", "75%"], // Set the inner and outer radius for the doughnut chart
          center: ["60%", "50%"],
          label: {
            show: false, // Hide the labels on the pie chart
          },
          data: [
            // { value: 0, name: "Low", itemStyle: { color: "#4CAF50" } }, // Green color for "Low"
            // { value: 0, name: "Medium", itemStyle: { color: "#FFC107" } }, // Yellow color for "Medium"
            // { value: 0, name: "High", itemStyle: { color: "#FF5722" } }, // Orange color for "High"
            // { value: 0, name: "Critical", itemStyle: { color: "#F44336" } }, // Red color for "Critical"
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      const newSeriesData = [
        {
          value: this.low,
          name: "Low",
          itemStyle: { color: "#28C76F" },
        },
        {
          value: this.medium,
          name: "Medium",
          itemStyle: { color: "#FF9F43" },
        },
        {
          value: this.high,
          name: "High",
          itemStyle: { color: "#EA5455" },
        },
      ];
      this.$set(this.series[0], "data", newSeriesData);
    },

    handleChartClick(section) {
      let value = null;
      if (section == "Low") {
        value = 1;
      } else if (section == "Medium") {
        value = 2;
      } else if (section == "High") {
        value = 3;
      }
      if (value != null) {
        this.$router.push(`/responses?risk_value=${value}`);
      }
    },
  },
};
</script>

<style></style>
