<template>
  <div
    class="card mb-0"
    @click="
      () => {
        $router.push(`/thirdpartyrisksRA/assessments`);
      }
    "
  >
    <div class="card-header">
      <div>
        <h4 class="font-weight-bold mb-0">Assessments Status</h4>
        <p class="card-text font-small-2" style="height: 25px;">
          Overall status of all the assessments are represented below.
        </p>
      </div>
    </div>

    <div
      class="card-body d-flex align-items-start justify-content-between flex-wrap w-100"
      style="height: 275px"
    >
      <assessment-chart v-if="inProgress||closed||notStarted" :series="series" />
      <div
          v-else
          class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <feather-icon icon="MehIcon" size="36" />
          <p class="mt-2 text-center w-75 text-muted">
            It appears that the we have no data available in assessment.
          </p>
        </div>
    </div>
  </div>
</template>

<script>
import RadialChart from "../../components/RadialChart.vue";
import AssessmentChart from "./components/AssessmentChart.vue";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    AssessmentChart,
    RadialChart,
    FeatherIcon
  },

  props: {
    inProgress: {
      type: Number,
      required: true,
    },

    closed: {
      type: Number,
      required: true,
    },

    notStarted: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      series: [
        {
          name: "Assessments",
          type: "pie",
          radius: ["50%", "75%"], // Set the inner and outer radius for the doughnut chart
          center: ["60%", "50%"],
          label: {
            show: false, // Hide the labels on the pie chart
          },
          data: [
            // { value: 0, name: "Low", itemStyle: { color: "#4CAF50" } }, // Green color for "Low"
            // { value: 0, name: "Medium", itemStyle: { color: "#FFC107" } }, // Yellow color for "Medium"
            // { value: 0, name: "High", itemStyle: { color: "#FF5722" } }, // Orange color for "High"
            // { value: 0, name: "Critical", itemStyle: { color: "#F44336" } }, // Red color for "Critical"
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      const newSeriesData = [
        {
          value: this.inProgress,
          name: "In Progress",
          itemStyle: { color: "#28c76f" },
        }, // Green color for "Low"
        {
          value: this.closed,
          name: "Closed",
          itemStyle: { color: "#ea5455" },
        }, // Yellow color for "Medium"
        {
          value: this.notStarted,
          name: "Not Started",
          itemStyle: { color: "#ff9f43" },
        }, // Orange color for "High"
      ];
      this.$set(this.series[0], "data", newSeriesData);
    },
  },
};
</script>

<style></style>

RiskItem
