<template>
  <div class="h-auto mb-1" style="width: 68%">
    <div class="w-100 d-flex align-items-center justify-content-start">
      <div class="mr-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          pill
          @click="
            () => {
              $router.push('/thirdpartyrisksRA/assessments/new');
            }
          "
        >
          <span
            class="align-middle text-center d-flex align-items-center justify-content-between"
          >
            <span>Start Your Assessment</span>
            <feather-icon icon="ArrowRightCircleIcon" class="ml-2" size="24" />
          </span>
        </b-button>
      </div>
      <div class="mr-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          pill
          @click="
            () => {
              $router.push('/thirdpartyrisksRA/assessments');
            }
          "
        >
          <span
            class="align-middle text-center d-flex align-items-center justify-content-between"
          >
            <span>All Assessments</span>
            <feather-icon icon="FileTextIcon" class="ml-2" size="24" />
          </span>
        </b-button>
      </div>
      <div class="mr-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          pill
          @click="
            () => {
              $router.push('/thirdparties?new=true');
            }
          "
        >
          <span
            class="align-middle text-center d-flex align-items-center justify-content-between"
          >
            <span>Invite Third Parties</span>
            <feather-icon icon="SendIcon" class="ml-2" size="24" />
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BButton,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
};
</script>

<style></style>
