<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
    @click="handleChartClick"
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    series: {
      type: Array,
      default: null,
    },

    legend: {
      type: Object,
      default: {
        orient: "vertical",
        left: 0,
        top: "top",
      },
      required: false,
    },
  },

  created() {
    this.$set(this.option, "legend", this.legend);
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          formatter: params => this.formatTooltip(params),
          position: ["50%", "50%"],
          confine: true,

          textStyle: {
            width: 300,
            height: 600,
            overflow: "truncate",
            // extraCssText:
            //   "max-width: 30vw;   white-space: normal;  word-wrap: break-word;",
          },
        },
        legend: null,

        series: this.series,
      },
    };
  },

  mounted() {},

  methods: {
    handleChartClick(event) {
      console.log(event);
      this.$emit("chart-click", event.name);
    },
    formatTooltip(params) {
        const maxLength = 50; // Set the maximum length of the tooltip content
        let content = params.name + "<br/>" + params.seriesName + ": " + params.value + " (" + params.percent + "%)";
        if (content.length > maxLength) {
          return `<div >${content.substring(0, maxLength) + "..."}</div>`;
        }
        return `<div >${content}</div>`;
      }
  },
};
</script>

<style scoped>
.echarts {
  width: inherit;
  height: inherit;
  position: relative;
}
</style>
