<script>
import { Pie } from "vue-chartjs";

import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        // responsiveAnimationDuration: 500,
        legend: {
          position: "right",
          align: "start",
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 40,
            fontColor: chartColors.labelColor,
            generateLabels: function (chart) {
              const originalGenerateLabels =
                Chart.defaults.pie.legend.labels.generateLabels;
              const labels = originalGenerateLabels.call(this, chart);

              labels.forEach((label) => {
                const truncatedLabel =
                  label.text.length > 60
                    ? label.text.substring(0, 60) + "..."
                    : label.text;
                label.text = truncatedLabel;
              });

              return labels;
            },
          },
        },

        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        animation: {
          animateRotate: false,
        },
      },
    };
  },

  watch: {
    data(newValue) {
      this.renderChart(newValue, this.options);
    },
  },

  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
