<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="dark">
    <div
      class="card mb-0"
      @click="
        () => {
          $router.push(`/thirdparties`);
        }
      "
    >
      <div
        class="card-header d-flex flex-column align-items-start justify-content-start pb-0"
      >
        <h4 class="font-weight-bold mb-0">Compliance</h4>
        <!-- <p class="mb-0">Compliance of all the</p> -->
      </div>
      <div
        class="card-body d-flex align-items-center justify-content-center"
        style="height: 280px"
      >
        <vue-apex-charts
          v-if="showOverlay == false"
          style="cursor: pointer"
          type="radialBar"
          height="280"
          width="280"
          :options="chartOptions"
          :series="value"
          :key="chartKey"
        ></vue-apex-charts>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BBadge, BOverlay } from "bootstrap-vue";
import NewDashboardMixins from "../../../mixins/NewDashboardMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
export default {
  mixins: [NewDashboardMixins, ResponseMixins],
  components: {
    VueApexCharts,
    BBadge,
    BOverlay,
  },

  props: {
    vendorId: {
      type: String,
      // required: true,
    },
  },

  data() {
    return {
      chartOptions: {
        chart: {
          height: 320,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            // startAngle: -135,
            // endAngle: 135,
            hollow: {
              margin: 0,
              size: "70%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: true,
                fontSize: ".95rem",
              },
              value: {
                fontSize: "1.8rem",
                fontWeight: "bold",
                show: true,
              },
            },
          },
        },
        colors: ["#30C8E0"],
        labels: ["Average Compliance"],

        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shade: "dark",
        //     type: "horizontal",
        //     gradientToColors: ["#28c76f", "#ea5455"],
        //     stops: [20, 100],
        //   },
        // },
        stroke: {
          lineCap: "round",
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
      showOverlay: false,
      value: [0],
      chartKey: 0,
    };
  },

  watch: {
    // Watch for changes in the optionData prop
    value() {
      this.forceRerender();
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getDashboardThirdPartiesCountsAndSetData({});
    },

    forceRerender() {
      this.chartKey += 1;
    },

    getDashboardThirdPartiesCountsAndSetData(params) {
      this.showOverlay = true;
      this.getDashboardThirdPartiesCounts({...params,vendorId:this.vendorId})
        .then((res) => {
          console.log("Avg Compliance", res);
          this.value = [Number(res.data.data.averageCompliance)];
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";

.apexcharts-legend {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
