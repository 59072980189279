<template>
  <vue-apex-charts
    style="cursor: pointer"
    type="radialBar"
    :options="chartOptions"
    :series="[value]"
    height="30"
    width="30"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

import { BBadge } from "bootstrap-vue";
export default {
  components: {
    VueApexCharts,
    BBadge,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "22%",
            },
            track: {
              background: "#e9ecef",
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        colors: ["#00cfe8"],
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       legend: {
        //         show: false,
        //       },
        //     },
        //   },
        // ],
      },
    };
  },

  created() {
    // const color = this.strokeColor(this.value);
    // this.$set(this.chartOptions, "colors", [color]);
    console.log("Color ", $themeColors.primary);
  },

  methods: {
    strokeColor(val) {
      if (val <= 25) {
        return "#2B1E33";
      } else if (val > 25 && val <= 50) {
        return "#322324";
      } else if (val > 50 && val <= 75) {
        return "#352B22";
      } else if (val > 75 && val <= 100) {
        return "#1F2F26";
      } else {
        return "#2B1E33";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";

.apexcharts-legend {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
