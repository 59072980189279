<template>
  <div class="w-100 h-auto d-flex flex-column align-items-center justify-content-start">
    <div class="d-flex align-items-start justify-content-between w-100">
      <actions-section />
      <div class="ml-auto" style="width:20%">
        <b-form-select v-model="thirdParty" :options="thirdPartyOptions" />
      </div>
    </div>
    <div class="d-flex align-items-start justify-content-between w-100">
      <div style="width: 22%">
        <average-compliance-card :key="thirdParty" :vendorId="thirdParty"/>
      </div>
      <div style="width: 40%">
        <third-parties-reviews />
      </div>
      <div style="width: 35%">
        <open-risks-section
          :key="thirdParty"
          :low="dashDetails.questionnaire_response_count_risk_low"
          :medium="dashDetails.questionnaire_response_count_risk_medium"
          :high="dashDetails.questionnaire_response_count_risk_High"
          :showOverlay="showOverlay"
        />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-around w-100">
        <vendors-count style="width:80%" class="mb-2 mt-0"/>
    </div>
    <div class="d-flex align-items-start justify-content-between w-100">
      <div style="width: 50%">
        <top-polices-secion :key="thirdParty" :vendorId="thirdParty"/>
      </div>
      <div style="width: 48%">
        <assessments-section
          :inProgress="dashDetails.assessments['1']"
          :closed="dashDetails.assessments['2']"
          :notStarted="dashDetails.assessments['3']"
          :showOverlay="showOverlay"
          :key="thirdParty"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewDashboardMixins from "../../mixins/NewDashboardMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import AverageComplianceCard from "./components/AverageComplianceCard.vue";
import OpenRisksSection from "./sections/Risks/OpenRisksSection.vue";
import VendorsCount from "./sections/Vendors/VendorsCount.vue";
import AssessmentsSection from "./sections/Assessments/AssessmentsSection.vue";
import ThirdPartiesReviews from "./sections/ThirdParties/ThirdPartiesReviews.vue";
import TopPolicesSecion from "./sections/Policies/TopPolicesSecion.vue";
import ActionsSection from "./sections/Actions/ActionsSection.vue";
import { BFormSelect } from "bootstrap-vue";
export default {
  components: {
    AverageComplianceCard,
    AssessmentsSection,
    ThirdPartiesReviews,
    OpenRisksSection,
    TopPolicesSecion,
    ActionsSection,
    VendorsCount,
    BFormSelect
  },
  data() {
    return {
       dashDetails: null, 
       showOverlay: false,
       thirdParty:null,
       thirdPartyOptions:[{value:null,text:'Organization Dashboard'}]

      };
  },

  computed: {
    averageCompliance() {
      if (
        this.dashDetails &&
        this.dashDetails != null &&
        this.dashDetails.compliance_percentage
      ) {
        let num = this.dashDetails.compliance_percentage;
        if (num % 1 !== 0) {
          return num.toFixed(2);
        } else {
          return num.toFixed(0);
        }
      }
      return 0;
    },
  },

  mixins: [NewDashboardMixins, ResponseMixins],

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getVendors()
      this.getDashboardDetailsAndSetData({});
    },
    async getVendors(){
     const response=await this.getDashboardVendors()
     const vendorOptions=response.data.data.map(item=>{
      // console.log('item',{value:item._id,text:item.name})
      return {value:item._id,text:item.name}
     })
     this.thirdPartyOptions=[...this.thirdPartyOptions,...vendorOptions]
    //  console.log('vendor',vendorOptions)
    },
    getDashboardDetailsAndSetData(params) {
      console.log('dashboardDetails',params)
      this.showOverlay = true;
      this.getDashboardDetails(params)
        .then((res) => {
          console.log("Dashboard Details", res);
          this.dashDetails = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

  },
  watch:{
      thirdParty(){
        this.getDashboardDetailsAndSetData({vendorId:this.thirdParty})
      }
    },
};
</script>

<style></style>
